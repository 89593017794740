@font-face {
  font-family: 'LowdragOutline-Extended';
  src: url(../../components/assets/fonts/LowdragOutline-Extended.woff2)
    format('woff2');
}
@font-face {
  font-family: 'LowdragOutline-Narrow';
  src: url(../../components/assets/fonts/LowdragOutline-Narrow.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Lowdrag-Narrow';
  src: url(../../components/assets/fonts/Lowdrag-Narrow.woff2) format('woff2');
}
@font-face {
  font-family: 'GrandSlang-B-Side';
  src: url(../../components/assets/fonts/GrandSlang-B-Side.woff2)
    format('woff2');
}
@font-face {
  font-family: 'GrandSlang-Italic';
  src: url(../../components/assets/fonts/GrandSlang-Italic.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Reforma1969-Gris';
  src: url(../../components/assets/fonts/Reforma1969-Gris.woff2) format('woff2');
}
@font-face {
  font-family: 'Reforma1969-NegraItalica';
  src: url(../../components/assets/fonts/Reforma1969-NegraItalica.woff2)
    format('woff2');
}
@font-face {
  font-family: 'Reforma1918-Negra';
  src: url(../../components/assets/fonts/Reforma1918-Negra.woff2)
    format('woff2');
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

*[tabIndex^='-1']:focus {
  outline: none;
}

html,
body,
#root,
#root > div {
  font-family: 'Work Sans Regular', sans-serif;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}

html {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* a:hover,
a:hover > *:not([class*='touchAction']) {
  text-decoration: underline;
} */

input:focus {
  outline: none !important;
}
